import BG from "./../../../../assets/images/hero.png"
import Video from "./../../../../assets/Video/video.mp4"
import Arrow from "./../../../../assets/icons/down-arrow.png"
import { HeaderHome } from "./../../../../components/index";
import "./../../../../index.css";

const Hero = () => {
	return (
		<div className="w-full h-screen relative overflow-hidden flex justify-center items-center bg-[#151f30]" id="hero">
			<HeaderHome></HeaderHome>
			<div className="absolute z-20 w-[95%]">
				<h1 className="font-serif font-normal text-white text-4xl text-center uppercase md:text-3xl">Sustainable <br className="hidden md:flex"></br> Real Estate <br className="hidden md:flex"></br> Transformations</h1>
			</div>
			<div className="absolute bottom-0 right-0 pr-20 pb-10 z-20 2md:hidden">
				<div className="flex justify-center items-center w-10 h-10">
					<a href="#about" className="w-10 h-10 border flex justify-center items-end p-2 cursor-pointer hover:w-9 hover:h-9 duration-200">
						<img src={Arrow}></img>
					</a>
				</div>
			</div>

			<video autoPlay={true} controls={false} muted playsInline loop className="object-cover object-center h-full w-full opacity-[45%] rounded-none z-10">
				<source type="video/mp4;charset=UTF-8" src={Video} />
			</video>
		</div>
	);
};

export default Hero;
