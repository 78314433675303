import React, { useState } from "react";
import { Routes, Route, HashRouter } from "react-router-dom";
import { Footer } from "./components/index";
import { Home, Error, Cases, ProjectLock } from "./pages/index"

function App() {
  return (
    <div className="App">
      <Routes>
          <Route path="/" element={<Home></Home>}></Route>
          <Route path="/project" element={<Cases></Cases>}></Route>
          <Route path="/project/:id" element={<ProjectLock></ProjectLock>}></Route>
          <Route path="*" element={<Error></Error>}></Route>
      </Routes >
    </div>
  );
}

export default App;
