import { Link } from "react-router-dom";

export interface ItemProps {
    id: string;
    url: string;
    img: string;
    namn: string;
    adress: string;
    status: string;
}

function Item(props: ItemProps) {
    return ( 
        <Link to={props.url} className="group">
            <div className="w-full flex flex-col bg-[#8ba5b7]">
                <div className="h-[15rem] w-full overflow-hidden">
                    <div className="w-full h-full bg-center bg-cover bg-no-repeat group-hover:scale-110 transition duration-500 ease-in-out" style={{
                        backgroundImage: `linear-gradient(to bottom, rgba(0,0,0, .25), rgba(0,0,0, .25)), url(${props.img})`,
                    }}></div>
                </div>
                <div className="w-full px-4 py-5">
                    <div className="flex flex-col w-full">
                        <h1 className="text-white text-xl tracking-wider font-normal uppercase font-serif">{props.namn}</h1>
                        <p className="text-white text-xs uppercase">{props.adress}</p>
                        <div className="flex flex-col mt-3">
                            <p className="text-white text-[10px] uppercase">status</p>
                            <h1 className="text-white text-sm uppercase tracking-wide font-medium">{props.status}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
     );
}

export default Item;