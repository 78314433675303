import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { Header, FooterRoute } from "../../components/index";
import { Banner, Status, About, Gallery } from "./sections/index";

/* Project Lock */

import props from "../project";

export interface ProjectProps {
	banner: string;
	title: string;
	quote1: string;
	quote2: string;
	about: string;
	img1?: string;
	img2?: string;
	img3?: string;
	img4?: string;
	images: number;
}

export default function Case() {
	const { id } = useParams();
	useEffect(() => {
		console.log({ id });
	}, [id]);
	const project = props.find((project) => project.id === id);
	console.log(project?.namn);
	if (!project) return null;
	return (
		<div className="w-full" id="case">
			<Banner banner={project.banner} title={project.title} />
			<About
				projectname={project.namn}
				quoteone={project.quote1}
				quotetwo={project.quote2}
				about={project.about}
			/>
			<Gallery
				image1={project.img1}
				image2={project.img2}
				image3={project.img3}
				image4={project.img4}
				images={project.images}
			/>
			<FooterRoute white />
		</div>
	);
}
