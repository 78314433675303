import { StringMappingType } from "typescript";

interface Props {
    projectname: string;
    quoteone: string;
    quotetwo: string;
    about: string;
}

function About(props: Props) {
    return ( 
        <div className="w-full flex items-center justify-center bg-white pb-[5%] pt-[5%]">
            <div className="w-[85%] flex pt-[8%] pb-[8%] justify-center lg:flex-col lg:items-center" id="about">

                {/* Hidden section */}

                <div className="w-2/4 flex justify-center lg:justify-start lg:w-3/4 lg:mb-6">
                    <div className="h-full flex flex-col gap-3 md:gap-2">
                        <div className="hidden justify-start items-center">
                            <div className="h-[2px] w-[1rem] bg-black mr-3"></div>
                            <h1 className="text-xl font-medium font-sans md:text-lg">About us</h1>
                        </div>
                        <div className="">
                            <h2 className="font-serif font-normal text-4xl uppercase md:text-3xl">Creating Real<br></br>Estate Value</h2>
                        </div>
                    </div>
                </div>

                <div className="w-3/4 flex pl-20 lg:pl-0 lg:w-3/4">
                    <p className="text-xl md:text-base">{props.about}</p>
                </div>
            </div>
        </div>
     );
}

export default About;