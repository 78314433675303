import { Link } from "react-router-dom";
import Logo from "./../../assets/Logo_Color_PNG.png";

export default function Error() {
	return (
	  <>
		<div className="flex min-h-full flex-col bg-white pt-16 pb-12">
		  <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
			<div className="flex flex-shrink-0 justify-center">
			  <a href="/" className="inline-flex">
				<span className="sr-only">ATELL</span>
				<img
				  className="h-12 w-auto"
				  src={Logo}
				  alt="Company logo"
				/>
			  </a>
			</div>
			<div className="py-16">
			  <div className="text-center">
				<p className="text-base font-sans font-semibold text-[#162030]ß">404</p>
				<h1 className="mt-2 text-4xl font-serif font-normal uppercase tracking-tight text-[#E2DBC2] sm:text-5xl">Page not found.</h1>
				<p className="mt-2 text-base font-sans text-gray-500">Sorry, we couldn’t find the page you’re looking for.</p>
				<div className="mt-6">
				  <Link to="/" className="text-base font-normal font-serif uppercase text-[#162030] hover:text-[#1620308f]">
					Go back home
					<span aria-hidden="true"> &rarr;</span>
				  </Link>
				</div>
			  </div>
			</div>
		  </main>
		</div>
	  </>
	)
  }
  