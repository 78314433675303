import { ItemProps } from "./Cases/item";
import { ProjectProps } from "./Case/project";

import pc1 from "./../assets/images/cases/project-lock/cover.png";
import pbanner1 from "./../assets/images/cases/project-lock/banner.png";
import pb1 from "./../assets/images/cases/project-lock/full-img.png";
import pb11 from "./../assets/images/cases/project-lock/half-img-one.png";
import pb12 from "./../assets/images/cases/project-lock/half-img-two.png";
import pb13 from "./../assets/images/cases/project-lock/half-img-three.png";

import pc2 from "./../assets/images/cases/project-kasernen-1/img2.png";
import pbanner2 from "./../assets/images/cases/project-kasernen-1/banner.png";
import pb2 from "./../assets/images/cases/project-kasernen-1/banner.png";
import pb21 from "./../assets/images/cases/project-kasernen-1/img2.png";
import pb22 from "./../assets/images/cases/project-kasernen-1/img1.png";
import pb23 from "./../assets/images/cases/project-kasernen-1/img2.png";

import pb24 from "./../assets/images/cases/project-crossbar/img.png";
import pb25 from "./../assets/images/cases/project-crossbar/img2.png";

interface Project extends ItemProps, ProjectProps {
	status: "ongoing" | "finnished";
}

const projects: Project[] = [
	{
		// Project cover item
		id: "lock",
		url: "/project/lock",
		img: pc1,
		namn: "Project lock",
		adress: "Eskilstuna",
		status: "ongoing",

		// Bottom page for project
		banner: pbanner1,
		title: "Project lock",
		quote1: "Creating Real",
		quote2: "Estate Value",
		about: "Project Lock is a 60 000 sqm industrial building in Eskilstuna, which was acquired from ASSA Abloy in 2018. By 2020 the property was fully let to ASSA, the Traffic Authorities (Trafikverket), the Regional Archives (Region Sörmland) and the Swedish Police. Large parts of the building is currently under refurbishment. Atell manages the property on behalf of CapMan Nordic Real Estate II.",
		img1: pb1,
		img2: pb11,
		img3: pb12,
		img4: pb13,
		images: 4,
	},
	 {
		// Project cover item
		id: "kasernen",
		url: "/project/kasernen",
		img: pc2,
		namn: "Kasernen 1",
		adress: "Stockholm, Solna",
		status: "ongoing",

		// Bottom page for project
		banner: pbanner2,
		title: "Project Kasernen 1",
		quote1: "Creating Real",
		quote2: "Estate Value",
		about: "Kasernen 1 is located in the Royal National Park (Hagaparken) in Solna and was acquired by JV between Atell and CapMan Nordic Real Estate III in an off-market transaction in 2022. The property consists of a former military building from the early 1900s, which is today used for offices. The property was extended with a ca 200 room hotel, conference areas, a spa and a restaurant in the early 1990s.",
		img1: pb2,
		img2: pb21,
		img3: pb22,
		img4: pb23,
		images: 4,
	},
	{
		// Project cover item
		id: "crossbar",
		url: "/project/crossbar",
		img: pb25,
		namn: "Crossbar",
		adress: "Nyköping",
		status: "ongoing",

		// Bottom page for project
		banner: pb25,
		title: "Crossbar",
		quote1: "Creating Real",
		quote2: "Estate Value",
		about: "Crossbar consists of the 40,000 sqm industrial building Ribban 5, located in central Nyköping.",
		img1: pb24,
		img2: pb25,
		images: 2,
	},
];

export default projects;
