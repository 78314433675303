import Item, { ItemProps } from "./item";
import { Listbox, Transition } from "@headlessui/react";
import { useState, Fragment } from "react";
import { Header, FooterRoute } from "./../../components/index";
import project from "./../project";

import Banner from "./../../assets/images/library-banner.png"

const filters = [
	{ name: "all", text: "Show all" },
	{ name: "ongoing", text: "Ongoing" },
    { name: "finnished", text: "Finnished" },
];

const Cases = () => {
	const [filter, setFilter] = useState(filters[0]);
    return ( 
        <>
            <main className="w-full flex flex-col items-center">
                <Header></Header>
                <div className="w-full flex justify-center items-center py-32 bg-center bg-cover bg-no-repeat" style={{
                        backgroundImage: `linear-gradient(to bottom, rgba(0,0,0, .25), rgba(0,0,0, .25)), url(${Banner})`,
                    }}>
                    <h1 className="text-4xl font-serif text-white uppercase font-normal tracking-wider lg:text-3xl">Case studies</h1>
                </div>
                 <div className="w-full bg-[#162030] flex justify-center py-16">
                    <div className="w-[85%] flex flex-col gap-1">
                        <h2 className="text-white text-xs mb-1 uppercase">Select filter</h2>

                        <div className="w-72">
                            <Listbox value={filter} onChange={setFilter}>
                                <div className="relative mt-1">
                                    <Listbox.Button className="relative w-full pointer  bg-white text-black py-2 pl-3 pr-10 text-left shadow-sm focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                        {filter.text}
                                    </Listbox.Button>
                                    <Transition
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0">
                                        <Listbox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto  bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                            {filters.map((_filter) => (
                                                <Listbox.Option
                                                    key={_filter.name}
                                                    className={({ active }) =>
                                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                                            active
                                                                ? "bg-[#162030] text-white"
                                                                : "text-gray-900"
                                                        }`
                                                    }
                                                    value={_filter}>
                                                    {({ selected }) => (
                                                        <span
                                                            className={
                                                                selected ? "font-bold" : "font-normal"
                                                            }>
                                                            {_filter.text}
                                                        </span>
                                                    )}
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </Transition>
                                </div>
                            </Listbox>
                        </div>
                    </div>
                </div> 

                <div className="w-full items-center pb-[5%] pt-[5%] flex flex-col">
                    <div className="w-[96%] grid grid-cols-4 gap-5 xl:grid-cols-3 lg:grid-cols-2 2md:grid-cols-1">
                        {project
                            .filter(
                                (property) => filter.name === "all" || property.status === filter.name
                            )
                            .map((property) => (
                                <Item key={property.namn} {...property} />
                            ))}
                    </div>
                </div>
            </main>
            <FooterRoute blue></FooterRoute>
        </>
     );
}

export default Cases;