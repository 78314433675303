import { useEffect, useState } from "react";

interface Props {
	image1?: string;
	image2?: string;
	image3?: string;
	image4?: string;
	images: number;
}

export default function Gallery({ image1, image2, image3, image4, images }: Props) {
	const [rowCol, setRowCol] = useState("");
	useEffect(() => {
		switch (images) {
			case 1:
				return setRowCol("grid-cols-1 grid-rows-1");
			case 2:
				return setRowCol("grid-cols-2 grid-rows-1 2lg:grid-cols-1");
			case 3:
				return setRowCol("grid-cols-2 grid-rows-2 2lg:grid-cols-1");
			case 4:
				return setRowCol("grid-cols-3 grid-rows-2 xl:grid-cols-2 2lg:grid-cols-1");
			default:
				return setRowCol("grid-cols-3 grid-rows-2");
		}
	}, [images]);
	return (
		<div className="w-full flex items-center justify-center bg-[#151f30] pb-[10%] pt-[10%]">
			<div className="w-[85%]">
				<div className={`w-full grid ${rowCol} gap-5 `}>
					{image1 && (
						<div
							className={`w-full bg-cover bg-center bg-no-repeat lg:h-[20rem] ${
								images == 3
									? "col-span-2 2lg:col-span-1"
									: images == 4
									? "col-span-3 xl:col-span-1"
									: ""
							} ${images === 1 || images === 2 ? "h-[35rem]" : "h-[25rem]"}`}
							style={{
								backgroundImage: `linear-gradient(to bottom, rgba(21,31,048, .1), rgba(21,31,048, .1)), url(${image1})`,
							}}
						></div>
					)}
					{image2 && (
						<div
							className={`w-full bg-cover bg-center bg-no-repeat col-span-1 2lg:col-span-1 lg:h-[20rem] ${
								images === 1 || images === 2 ? "h-[35rem]" : "h-[25rem]"
							}`}
							style={{
								backgroundImage: `linear-gradient(to bottom, rgba(21,31,048, .1), rgba(21,31,048, .1)), url(${image2})`,
							}}
						></div>
					)}

					{image3 && (
						<div
							className="w-full h-[25rem] bg-cover bg-center bg-no-repeat col-span-1 2lg:col-span-1 lg:h-[20rem]"
							style={{
								backgroundImage: `linear-gradient(to bottom, rgba(21,31,048, .1), rgba(21,31,048, .1)), url(${image3})`,
							}}
						></div>
					)}

					{image4 && (
						<div
							className="w-full h-[25rem] bg-cover bg-center bg-no-repeat 2lg:col-span-1 lg:h-[20rem]"
							style={{
								backgroundImage: `linear-gradient(to bottom, rgba(21,31,048, .1), rgba(21,31,048, .1)), url(${image4})`,
							}}
						></div>
					)}
				</div>
			</div>
		</div>
	);
}
