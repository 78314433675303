import { CaseStudy, Hero, About, Agents } from "./Sections";
import { Footer } from "./../../components/index";

const Home = () => {
	return (
		<main className="w-[100vw] sm:overflow-hidden flex flex-col items-center">
			<Hero></Hero>
			<About></About>
			<CaseStudy></CaseStudy>
			<Agents></Agents>
			<Footer white></Footer>
		</main>
	);
};

export default Home;
