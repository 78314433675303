import { Header } from "./../../../../components/index";

interface BannerProps {
    banner: string;
    title: string;
}

function Banner(Props: BannerProps) {
    return ( 
        <div className="h-[30rem] w-full flex justify-center items-center bg-cover bg-center bg-no-repeat" id="banner" style={{backgroundImage: `linear-gradient(to bottom, rgba(21,31,048, .65), rgba(21,31,048, .65)), url(${Props.banner})`}}>
            <Header></Header>
            <div className="flex flex-col items-center">
                <h1 className="font-serif text-white font-normal uppercase text-5xl md:text-3xl">{Props.title}</h1>
            </div>
        </div>
     );
}

export default Banner;